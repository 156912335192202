var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('page-banner',{attrs:{"links":"Mes ressources"}}),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogNew),callback:function ($$v) {_vm.dialogNew=$$v},expression:"dialogNew"}},[_c('v-card',[_c('v-card-title',[_vm._v("Nouvelle ressource ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Nom de la ressource","autofocus":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.dialogNew = false;
                        _vm.newSynopsis(true);}},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){_vm.dialogNew = false;
                        _vm.newName = '';}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"success darken-2","text":""},on:{"click":function($event){_vm.dialogNew = false;
                        _vm.newSynopsis(true);}}},[_vm._v(" Créer la ressource ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v("Suppression de \""+_vm._s(_vm.synopsisToDeleteTitle)+"\"")]),_c('v-card-text',[_vm._v("Êtes vous sûr de vouloir supprimer cette ressource ? Si oui veuillez écire \"Supprimer\" dans le champs ci-dessous")]),_c('v-card-text',[_c('v-text-field',{attrs:{"autofocus":""},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false;
                        _vm.askForDelete = null;
                        _vm.confirmDelete = '';}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"error darken-2","text":"","disabled":_vm.confirmDelete !== 'Supprimer'},on:{"click":function($event){_vm.dialogDelete = false;
                        _vm.confirmDelete = '';
                        _vm.deleteSynopsis();}}},[_vm._v(" Supprimer ")])],1)],1)],1),_c('v-row',{staticClass:"mt-10 mb-7"},[_c('span',{staticClass:"text-h5"},[_vm._v("Ma bibliothèque de ressources")])]),_c('v-row',[_c('v-col',{staticClass:"formation_list pa-0"},_vm._l((_vm.filteredSynopsis),function(synopsis){return _c('v-row',{key:synopsis.id},[_c('v-card',{staticClass:"py-6 px-5 rounded-0",attrs:{"width":"100%"},on:{"click":function($event){return _vm.goTo(synopsis.id)}}},[_c('v-row',[_c('v-col',{staticClass:"align-self-center",attrs:{"md":"5"}},[_vm._v(" "+_vm._s(synopsis.title)+" ")]),_c('v-col',{staticClass:"text-center align-self-center",attrs:{"md":"1","offset-md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('v-btn',{staticClass:"hoverBtn",class:{ onHover: hover },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogDelete = true;
                                    _vm.askForDelete = synopsis.id;}}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1)]}}],null,true)})],1)],1)],1)],1)}),1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.newSynopsis}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("fas fa-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }