<template>
    <v-container fluid>
        <page-banner links="Mes ressources"></page-banner>

        <v-dialog v-model="dialogNew" max-width="400">
            <v-card>
                <v-card-title>Nouvelle ressource </v-card-title>
                <v-card-text>
                    <v-text-field
                        label="Nom de la ressource"
                        v-model="newName"
                        autofocus
                        @keydown.enter="
                            dialogNew = false;
                            newSynopsis(true);
                        "
                    ></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error darken-1"
                        text
                        @click="
                            dialogNew = false;
                            newName = '';
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="success darken-2"
                        text
                        @click="
                            dialogNew = false;
                            newSynopsis(true);
                        "
                    >
                        Créer la ressource
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400">
            <v-card>
                <v-card-title>Suppression de "{{ synopsisToDeleteTitle }}"</v-card-title>
                <v-card-text
                >Êtes vous sûr de vouloir supprimer cette ressource ? Si oui veuillez écire "Supprimer" dans le champs
                    ci-dessous</v-card-text
                >
                <v-card-text><v-text-field v-model="confirmDelete" autofocus></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            dialogDelete = false;
                            askForDelete = null;
                            confirmDelete = '';
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="error darken-2"
                        text
                        :disabled="confirmDelete !== 'Supprimer'"
                        @click="
                            dialogDelete = false;
                            confirmDelete = '';
                            deleteSynopsis();
                        "
                    >
                        Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row class="mt-10 mb-7">
            <span class="text-h5">Ma bibliothèque de ressources</span>
        </v-row>

        <v-row>
            <v-col class="formation_list pa-0">
                <v-row v-for="synopsis in filteredSynopsis" :key="synopsis.id">
                    <v-card width="100%" class="py-6 px-5 rounded-0" @click="goTo(synopsis.id)">
                        <v-row>
                            <v-col md="5" class="align-self-center">
                                {{ synopsis.title }}
                            </v-col>
                            <v-col md="1" offset-md="6" class="text-center align-self-center">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        icon
                                        class="hoverBtn"
                                        :class="{ onHover: hover }"
                                        @click.stop="
                                        dialogDelete = true;
                                        askForDelete = synopsis.id;
                                    ">
                                        <v-icon>far fa-trash-alt</v-icon>
                                    </v-btn>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-btn icon @click="newSynopsis"><v-icon color="black">fas fa-plus</v-icon></v-btn>
        </v-row>
    </v-container>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import { mapActions, mapState } from "vuex";

export default {
    name: "SynopsisList",

    components: {
        PageBanner,
    },

    data: () => ({
        dialogNew: false,

        dialogDelete: false,
        confirmDelete: "",
        askForDelete: "",

        newName: "",

        searchParam: "",
    }),

    created() {
        this.getSynopsis();
    },

    computed: {
        ...mapState("synopsis", ["synopsis"]),
        ...mapState("settings", ["user"]),


        filteredSynopsis() {
            return this.synopsis.filter((obj) => obj.title.toLowerCase().includes(this.searchParam.toLowerCase()));
        },

        synopsisToDeleteTitle() {
            try {
                return this.synopsis.find((obj) => obj.id === this.askForDelete).title;
            } catch (e) {
                return "";
            }
        },
    },

    methods: {
        ...mapActions("synopsis", ["getSynopsis", "setCurrentSynopsis", "addSynopsis", "removeSynopsis"]),

        goTo(e) {
            this.setCurrentSynopsis(e);
            this.$router.push("/synopsis/" + e + "/");
        },

        deleteSynopsis() {
            this.removeSynopsis(this.askForDelete);
        },

        async newSynopsis(toCreate) {
            if (toCreate === true) {
                const synopsisId = await this.addSynopsis(this.newName);

                this.goTo(synopsisId);
            } else {
                this.dialogNew = true;
            }
        },
    },
};
</script>

<style scoped>
.hoverBtn {
    transition: opacity 0.2s ease-in-out;
}

.hoverBtn:not(.onHover) {
    opacity: 0.5;
}

.formation_list > .row{
    margin: 1px 0;
}
</style>
